import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import defaultFields from "./DefaultFields";

function AddUser(props) {
  const [config, setConfig] = useState({});
  const [product, setProduct] = useState([]);
  const [type, setType] = useState(null);
  const [allSelected, setAllSelected] = useState(false);
  const [fieldSelect, setFieldSelect] = useState(null);

  const handleChange = (e, type) => {
    let newData = { ...signupData };
    if (type === "dropdown") {
      newData["access"][e.target.name] = e.target.value;
    } else {
      newData[e.target.name] = e.target.value;
    }
    setSignupData(newData);
  };
  const handleConfirmPass = (e) => {
    if (e.target.value !== signupData.password) {
      alert("password not matching!");
    } else {
      setConfPass(e.target.value);
    }
  };
  const handleEdit = () => {
    props.getUsers(props.email).then((res, rej) => {
      setSignupData((prev) => {
        return { ...prev, ...res, access: { ...prev.access, ...res.access } };
      });
      setProduct(res.access.products);
      setOpen(true);
    });
  };
  const handleSubmit = () => {
    if (signupData.password === confPass) {
      const url = props.edit
        ? process.env.REACT_APP_API_URL + "users/edituser"
        : process.env.REACT_APP_API_URL + "users/adduser";
      const options = {
        method: "POST",
        data: signupData,
      };
      axios.post(url, options).then((res) => {
        handleCancel();
        props.setRefresh((prev) => !prev);
      });
    } else {
      alert("password not matching!");
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const [signupData, setSignupData] = useState({
    username: "",
    email: "",
    password: "",
    type: "",
    access: {
      products: [],
      sections: [],
      fields: {},
    },
  });
  const [confPass, setConfPass] = useState("");
  const products = [
    "VideoPlayer",
    "HeaderBidding",
    "NewsBot",
    "EventsWidget",
    "CricketWidget",
    "AppMonetization",
    "GenericCube",
    "DirectCampaign",
    "AMPGists",
  ];
  const [accessTags, setAccessTags] = useState(false);
  const types = ["admin", "operations", "tech", "qc", "sales"];
  const sections = [
      "clients",
      "tasks",
      "sellers",
      "leads",
      "quiztwiz",
      "subdomains",
      "users",
  ];
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (props.open) {
      setOpen(props.open);
    }
  }, [props.open]);

  useEffect(() => {
    const url = `https://cdn.unibots.in/dashfields/VideoPlayer/productAdditionalFields.json`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setConfig((prev) => data["VideoPlayer"]);
      });
  }, []);
  const handleFieldChange = (e) => {
    let data = JSON.parse(JSON.stringify(signupData));
    if (e.target.value.includes("selectall")) {
      data["access"]["fields"]["VideoPlayer"] = config.map((el) => el.name);
      setAllSelected(true);
    } else if (e.target.value.includes("removeall")) {
      data["access"]["fields"]["VideoPlayer"] = [];
      setAllSelected(false);
    } else {
      console.log(data);
      data["access"]["fields"]["VideoPlayer"] = e.target.value;
    }
    setSignupData(data);
  };
  useEffect(() => {
    handleFieldChange({
      target: { value: defaultFields[type]?.VideoPlayer ?? [] },
    });
  }, [type]);
  useEffect(() => {
    if (product?.includes("VideoPlayer")) {
      setFieldSelect(
        <Stack spacing={2} direction="row" justifyContent="center" margin={2}>
          <FormControl sx={{ m: 2, width: "100%" }}>
            <InputLabel id="products">Choose Fields</InputLabel>
            <Select
              value={signupData.access.fields?.VideoPlayer ?? []}
              label="Select Fields"
              name="fields"
              onChange={handleFieldChange}
              multiple={true}
            >
              <MenuItem value={allSelected ? "removeall" : "selectall"}>
                {allSelected ? "Remove All" : "Select All"}
              </MenuItem>
              {config.map((e, index) => {
                return (
                  <MenuItem key={index} value={e.name}>
                    {e.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
      );
    }
  }, [product, signupData, allSelected]);

  return (
    <div>
      <Dialog open={open} fullWidth maxWidth="xs">
        <DialogTitle>{props.edit ? "Edit User" : "Add User"}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} direction="row" justifyContent="center" margin={2}>
            <TextField
              defaultValue={signupData.username}
              onBlur={handleChange}
              name="username"
              label="User Name"
              sx={{ width: "90%" }}
              autoComplete="new-username"
            />
          </Stack>
          <Stack spacing={2} direction="row" justifyContent="center" margin={2}>
            <TextField
              defaultValue={signupData.email}
              onBlur={handleChange}
              name="email"
              label="Email"
              type="email"
              sx={{ width: "90%" }}
              autoComplete="new-email"
            />
          </Stack>
          <Stack spacing={2} direction="row" justifyContent="center" margin={2}>
            <TextField
              defaultValue={signupData.password}
              onBlur={handleChange}
              name="password"
              label="Password"
              sx={{ width: "90%" }}
              autoComplete="new-password"
            />
          </Stack>
          <Stack spacing={2} direction="row" justifyContent="center" margin={2}>
            <TextField
              defaultValue={confPass}
              onBlur={handleConfirmPass}
              name="confpassword"
              label="Confirm Password"
              type="password"
              sx={{ width: "90%" }}
              autoComplete="new-password"
            />
          </Stack>
          <Stack spacing={2} direction="row" justifyContent="center" margin={2}>
            <FormControl sx={{ m: 2, width: "100%" }}>
              <InputLabel id="type">Account Type</InputLabel>
              <Select
                value={signupData.type}
                label="Choose Account Type"
                name="type"
                onChange={(e) => {
                  handleChange(e);
                  setType(e.target.value);
                }}
              >
                {types.map((e, index) => {
                  return (
                    <MenuItem key={index} value={e}>
                      {e}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
          <Stack spacing={2} direction="row" justifyContent="center" margin={2}>
            <FormControl sx={{ m: 2, width: "100%" }}>
              <InputLabel id="products">Choose Product</InputLabel>
              <Select
                value={signupData.access.products}
                label="Choose Products"
                name="products"
                onChange={(e) => {
                  handleChange(e, "dropdown");
                  setProduct(e.target.value);
                }}
                multiple={true}
              >
                {products.map((e, index) => {
                  return (
                    <MenuItem key={index} value={e}>
                      {e}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
          {fieldSelect}
          {/* {signupData.access.products.includes("VideoPlayer") && (
            <SwitchInput
              field={{
                label: "Ad tags access",
                name: "adTags",
                isBool: true,
                style: { width: "20%", ml: 5 },
              }}
              data={{
                prodDetails: { adTags: accessTags },
                handleSubChange: (e) => {
                  setAccessTags(e.target.checked);
                },
              }}
            />
          )} */}
          <Stack spacing={2} direction="row" justifyContent="center" margin={2}>
            <FormControl sx={{ m: 2, width: "100%" }}>
              <InputLabel id="sections">Choose Sections</InputLabel>
              <Select
                value={signupData.access.sections}
                label="Choose Sections"
                name="sections"
                onChange={(e) => {
                  handleChange(e, "dropdown");
                }}
                multiple={true}
              >
                {sections.map((e, index) => {
                  return (
                    <MenuItem key={index} value={e}>
                      {e}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="contained" onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {props.edit ? (
        <Button variant="contained" onClick={handleEdit}>
          Edit
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
}

export default AddUser;

import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Drawer,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  Avatar,
} from "@material-ui/core";
import {
  AddCircleOutlined,
  SubjectOutlined,
  GroupOutlined,
  SignalCellularAltOutlined,
  EmojiPeopleOutlined,
  HomeOutlined,
} from "@mui/icons-material";
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import IconButton from "@material-ui/core/IconButton";
// import GroupsIcon from '@mui/icons-material/Groups';
import { useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import "../css/style.css";
import { useSelector } from "react-redux";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
    },
    page: {
      // width: "100%",
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    active: {
      background: "#303030",
    },

    title: {
      padding: theme.spacing(2),
    },
    appbar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      // padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    appTitle: {
      flexGrow: 1,
    },
    avatar: {
      marginLeft: theme.spacing(1),
    },
  };
});

export default function Layout(props) {
  const userData = useSelector((state) => state.userData);
  const sectionAccess = userData?.access?.sections ?? [];
  const userName = userData.user_name;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);

  const handleDrawerToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const userItems = [
    {
        text: "Home",
        icon: <SubjectOutlined />,
        path: "/",
        name: "home",
    },
    {
        text: "Tasks",
        icon: <AddCircleOutlined />,
        path: "/tasks",
        name: "tasks",
    },
    // {
    //     text: "Inventory",
    //     icon: <AllInboxOutlined color="primary" />,
    //     path: "/inventory"
    // },
    {
        text: "Clients",
        icon: <GroupOutlined />,
        path: "/clients",
        name: "clients",
    },
    {
        text: "Sellers",
        icon: <EmojiPeopleOutlined />,
        path: "/sellers",
        name: "sellers",
    },
    {
        text: "Leads",
        icon: <SignalCellularAltOutlined />,
        path: "/leads",
        name: "leads",
    },
    {
        text: "QuizTwiz",
        icon: <QuizOutlinedIcon />,
        path: "/quiztwiz",
        name: "quiztwiz",
    },
    {
        text: "Subdomains",
        icon: <LanguageIcon />,
        path: "/subdomains",
        name: "subdomains",
    },
    {
        text: "Users",
        icon: <GroupOutlined />,
        path: "/users",
        name: "users",
    },
  ];
  // const adminItems = [
  //   {
  //     text: "Home",
  //     icon: <HomeOutlined />,
  //     path: "/admin",
  //     name: "home",
  //   },
  //   {
  //     text: "Users",
  //     icon: <GroupOutlined />,
  //     path: "/users",
  //     name: "users",
  //   },
  // ];
  const adminPaths = ["/admin", "/users", "/fields"];
  const [drawerItems, setDrawerItems] = useState(userItems);
  useEffect(() => {

      setDrawerItems(userItems);
  }, [userData]);
  function handleDrawerClick(data) {
    if (props.clientreset) {
      if (window.confirm("All Changes will be lost. Are you sure? ")) {
        history.push(data);
        props.clientEdited(false);
        props.changeClientFlag();
      }
    } else {
      history.push(data);
      props.changeClientFlag();
    }
  }
  function handleLogout() {
    props.setCookieStatus("");
  }
  function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }
  return (
    <>
      {userData?.user_type ? (
        <div className={classes.root}>
          {/* app bar */}
          <AppBar
            className={clsx(classes.appbar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" className={classes.appTitle}>
              Unibots Dashboard
              </Typography>
              <Typography>{userName}</Typography>
              <Button id="navbar-avatar">
                <Avatar
                  style={{ backgroundColor: stringToColor(userName) }}
                  children={userName.slice(0, 1)}
                />
              </Button>
              <div className="navbar-logout">
                <a href="javascript:void(0)" onClick={(e) => handleLogout()}>
                  <span style={{ color: "white", textDecoration: "underline" }}>
                    LOGOUT&nbsp;
                    <span style={{ position: "relative", top: "6px" }}>
                      <Icon>logout</Icon>
                    </span>
                  </span>
                </a>
              </div>
            </Toolbar>
          </AppBar>

          {/* side drawer */}
          <Drawer
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            variant="permanent"
            anchor="left"
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            {/* <div>
          <Typography variant="h6" className={classes.title}>
            Unibots
          </Typography>
        </div> */}
            {/* List Links */}
            <List>              
              {drawerItems.map((item) => (
                <ListItem
                  key={item.text}
                  button
                  disabled={                    
                    !sectionAccess.includes(item.name) &&
                    item.name !== "home" 
                    &&
                    !(
                      userData?.user_type?.includes("admin")
                    )
                  }
                  onClick={() => {
                    handleDrawerClick(item.path);
                  }}
                  className={
                    location.pathname === item.path ? classes.active : null
                  }
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              ))}
            </List>
          </Drawer>

          <div className={classes.page}>
            <div className={classes.toolbar}></div>
            {props.children}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

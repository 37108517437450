import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";

const JsonLink = (props) => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    let pathVar;
    let baseUrl = "cdn.unibots.in";
    // switch (props.heading) {
    //   case "VideoPlayer":
    //     pathVar = "clientdata";
    //     baseUrl = "cdn.unibotscdn.com";
    //     break;
    //   case "HeaderBidding":
    //     pathVar = "headerbidding";
    //     break;
    //   case "NewsBot":
    //     pathVar = "newsbot";
    //     break;
    //   case "CricketWidget":
    //     pathVar = "cricketwidget";
    //     break;
    //   case "EventsWidget":
    //     pathVar = "eventswidget";
    //     break;
    //   case "GenericCube":
    //     pathVar = "genericcube";
    //     break;
    //   case "DirectCampaign":
    //     pathVar = "directcampaign";
    //     break;
    // }
    
    pathVar = (process.env.REACT_APP_MODE === "prod") ? (props.heading.toLowerCase()) : (props.heading.toLowerCase() + "_test");
    let urlLink = `https://${baseUrl}/${pathVar}/${props.clientName}.json`;
    setUrl(urlLink);
  }, [props.heading, props.clientName]);

  return (
    <div>
      <Button
        onClick={() => {
          window.open(url, "_blank");
        }}
      >
        Open JSON
      </Button>
    </div>
  );
};

export default JsonLink;

import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"; // Alternative icon

const RenderLogsDialog = ({ logs, logsOpen, setLogsOpen }) => {
    return (
        <Dialog
            open={logsOpen}
            onClose={() => setLogsOpen(false)}
            sx={{
                "& .MuiDialog-paper": {
                    width: "60%",
                    maxWidth: "600px",
                    height: "60%",
                    maxHeight: "80%",
                },
            }}
        >
            <DialogTitle>
                <Typography variant="h6" fontWeight="bold" align="center">
                    Change Logs
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ overflowY: "auto" }}>
                {logs ? (
                    logs.length > 0 ? (
                        logs
                            .slice() // Create a shallow copy to avoid mutating the original array
                            .reverse() // Reverse the order of the logs
                            .map((log, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        border: "1px solid #e0e0e0",
                                        borderRadius: 2,
                                        p: 0.5,
                                        mb: 2,
                                        // backgroundColor: "#f9f9f9",
                                    }}
                                >
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ArrowDownwardIcon />}
                                            aria-controls={`panel${index}-content`}
                                            id={`panel${index}-header`}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight="bold"
                                            >
                                                {log.action} by {log.user}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                gutterBottom
                                            >
                                                {log.timestamp || log.time}
                                            </Typography>
                                            <Divider sx={{ my: 1 }} />
                                            <Typography
                                                variant="subtitle2"
                                                fontWeight="bold"
                                            >
                                                CHANGES:
                                            </Typography>
                                            {typeof log.changes === "string" ? (
                                                <Typography
                                                    variant="body2"
                                                    sx={{ mt: 1 }}
                                                >
                                                    {log.changes}
                                                </Typography>
                                            ) : (
                                                <List dense>
                                                    {Object.entries(
                                                        log.changes
                                                    ).map(
                                                        (
                                                            [field, change],
                                                            i
                                                        ) => (
                                                            <ListItem
                                                                key={i}
                                                                sx={{ pl: 0 }}
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        <Box>
                                                                            <Typography
                                                                                variant="body2"
                                                                                fontWeight="bold"
                                                                                display="inline"
                                                                            >
                                                                                {
                                                                                    field
                                                                                }
                                                                                :{" "}
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="error"
                                                                                display="inline"
                                                                            >
                                                                                {typeof change.oldValue ===
                                                                                "boolean"
                                                                                    ? change.oldValue ===
                                                                                      true
                                                                                        ? "True"
                                                                                        : "False"
                                                                                    : change.oldValue ||
                                                                                      "N/A"}
                                                                            </Typography>
                                                                            {
                                                                                " → "
                                                                            }
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="success.main"
                                                                                display="inline"
                                                                            >
                                                                                {typeof change.newValue ===
                                                                                "boolean"
                                                                                    ? change.newValue ===
                                                                                      true
                                                                                        ? "True"
                                                                                        : "False"
                                                                                    : change.newValue ||
                                                                                      "N/A"}
                                                                            </Typography>
                                                                        </Box>
                                                                    }
                                                                />
                                                            </ListItem>
                                                        )
                                                    )}
                                                </List>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                            ))
                    ) : (
                        <Typography align="center" color="textSecondary">
                            No logs available.
                        </Typography>
                    )
                ) : (
                    <Typography align="center" color="textSecondary">
                        Loading logs...
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setLogsOpen(false)}
                    variant="contained"
                    color="primary"
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RenderLogsDialog;

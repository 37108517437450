import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setAppDetails,
  setObjCounter,
  setSubObjCounter,
  setObjFields,
  setSubObjFields,
  setBidderParams,
  setSubBidderParams,
  setBidderLabel,
  setInnerBidderLabel,
  setBiddersDisabled,
} from "../../redux/actions/commonActions";
import SetFields from "./SetFields";
import { Buffer } from "buffer";
import axios from "axios";

function ObjGroup(props) {
  const dispatch = useDispatch();
  const field_data = props.field_data;
  const addProdDetails = useSelector((state) => state.common.addProdDetails);
  const objectGroupCounter = useSelector(
    (state) => state.common.objectGroupCounter
  );
  const objectGroupDetails = useSelector(
    (state) => state.common.objectGroupDetails
  );
  const subObjectGroupCounter = useSelector(
    (state) => state.common.subObjectGroupCounter
  );
  const subObjectGroupDetails = useSelector(
    (state) => state.common.subObjectGroupDetails
  );

  const hbParams = useSelector((state) => state.common.hbParams);
  const subHbParams = useSelector((state) => state.common.subHbParams);
  const bidderLabel = useSelector((state) => state.common.bidderLabel);
  const innerBidderLabel = useSelector(
    (state) => state.common.innerBidderLabel
  );
  const biddersDisabled = useSelector((state) => state.common.biddersDisabled);
  const userData = useSelector((state) => state.userData);
  const [trigger, setTrigger] = useState(true);
  const [fields, setFields] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [gcCubeType, setGcCubeType] = useState("mgid");
  const [directAdType,setDirectAdType] = useState("mgid");
 
  const placeHolders = {
    colombia: { adUnitName: "enter data-section", customAdId: "466738" },
    outbrain: { adUnitName: "enter data-widget-id e.g. AR_5", customAdId: "" },
    nativclick: { adUnitName: "enter number from the script e.g 8421", customAdId: "enter div ID" },
    game: { adUnitName: "", customAdId: "" },
    mgid: {
      adUnitName: "Enter src from script tags e.g https://jsc.mgid.com/u/n/unibots.clientname.com.1440029.js",
      customAdId: "Enter id from div tags e.g M874992ScriptRootC1111111",
    },
    dc: {},
    mgid_v2: {
      adUnitName: "",
      customAdId: "",
    },
  };

  let objectgroup_fields = {};
  let subobjectgroup_fields = {};

  useEffect(() => {
    if (props.heading === "GenericCube") {
      setGcCubeType(addProdDetails.cubeType ? addProdDetails.cubeType : "mgid");
      setDirectAdType(addProdDetails.directAdType ? addProdDetails.directAdType : "mgid");
    }
  },[props.heading, addProdDetails])
  const isArr = (el) => {
    return Object.prototype.toString.call(el) == "[object Array]";
  };
  const addMoreFields = (objname) => {
    let count = props.botCatNum;
    field_data.forEach((field) => {
      if (
        field.name === objname &&
        field.addCount !== undefined &&
        field.addCount < props.botCatNum
      ) {
        count = field.addCount++;
      }
    });
    if (
      (props.heading === "NewsBot" || props.heading === "EventsWidget") &&
      count < props.botCatNum
    ) {
      objectgroup_fields = objectGroupDetails;
      subobjectgroup_fields = subObjectGroupDetails;
      let index = objectGroupCounter[objname].length;
      let field_obj = field_data.filter((obj) => {
        return obj.name === objname;
      });
      field_obj[0].fields.forEach((el) => {
        objectgroup_fields[objname][el.name + index] =
          typeof el.default == "object" ? {} : el.default?.toString();
        if (el.type == "objectgroup") {
          el.fields.forEach((subfield) => {
            subobjectgroup_fields[objname + el.name][
              subfield.name + index + "0"
            ] =
              typeof subfield.default == "object"
                ? {}
                : subfield.default.toString();
          });
        }
      });
      dispatch(setObjFields(objectgroup_fields));
      dispatch(setSubObjFields(subobjectgroup_fields));
      let arr = objectGroupCounter[objname];
      arr.push({});
      dispatch(
        setObjCounter({
          ...objectGroupCounter,
          [objname]: arr,
        })
      );
      for (let key in subObjectGroupCounter) {
        if (key.includes(objname)) {
          let subarr = subObjectGroupCounter[key];
          subarr.push([{}]);
          dispatch(
            setSubObjCounter({
              ...subObjectGroupCounter,
              [key]: subarr,
            })
          );
        }
      }
    } else if (
      props.heading !== "EventsWidget" &&
      props.heading !== "NewsBot"
    ) {
      objectgroup_fields = objectGroupDetails;
      subobjectgroup_fields = subObjectGroupDetails;
      let index = objectGroupCounter[objname].length;
      let field_obj = field_data.filter((obj) => {
        return obj.name === objname;
      });
      field_obj[0].fields.forEach((el) => {
        objectgroup_fields[objname][el.name + index] =
          typeof el.default == "object" ? {} : el.default?.toString();
        if (el.type == "objectgroup") {
          el.fields.forEach((subfield) => {
            subobjectgroup_fields[objname + el.name][
              subfield.name + index + "0"
            ] =
              typeof subfield.default == "object"
                ? {}
                : subfield.default.toString();
          });
        }
      });
      dispatch(setObjFields(objectgroup_fields));

      dispatch(setSubObjFields(subobjectgroup_fields));
      let arr = objectGroupCounter[objname];

      arr.push({});
      dispatch(
        setObjCounter({
          ...objectGroupCounter,
          [objname]: arr,
        })
      );
      for (let key in subObjectGroupCounter) {
        if (key.includes(objname)) {
          let subarr = subObjectGroupCounter[key];
          subarr.push([{}]);
          dispatch(
            setSubObjCounter({
              ...subObjectGroupCounter,
              [key]: subarr,
            })
          );
        }
      }
    }
  };
  // to add one more section in header bidding object group inside a object group (like bidders etc)
  const addMoreSubFields = (objname, childobjname, i) => {
    let subobjectgroup_fields = subObjectGroupDetails;
    let index = subObjectGroupCounter[objname + childobjname][i].length;
    let arr = subObjectGroupCounter[objname + childobjname];
    arr[i].push({});
    let field_obj = field_data.filter((obj) => {
      return obj.name === objname;
    });
    let inner_field_obj = field_obj[0].fields.filter((obj) => {
      return obj.name === childobjname;
    });
    inner_field_obj[0].fields.forEach((el) => {
      subobjectgroup_fields[objname + childobjname][el.name + i + index] =
        typeof el.default == "object" ? {} : el.default.toString();
    });

    dispatch(setSubObjFields(subobjectgroup_fields));
    dispatch(
      setSubObjCounter({
        ...subObjectGroupCounter,
        [objname + childobjname]: arr,
      })
    );
  };

  // delete the accordian added
  const deleteMoreFields = (objname, j) => {
    let bidLabels = JSON.parse(JSON.stringify(bidderLabel));
    field_data.forEach((field) => {
      if (field.name === objname && field.addCount !== undefined) {
        field.addCount--;
      }
    });
    let arr = objectGroupCounter[objname];
    arr.splice(j, 1);
    dispatch(
      setObjCounter({
        ...objectGroupCounter,
        [objname]: arr,
      })
    );
    let del_obj = objectGroupDetails[objname];
    let field_obj = field_data.filter((obj) => {
      return obj.name === objname;
    });
    field_obj[0].fields.forEach((el) => {
      for (let i = j; i < arr.length; i++) {
        let k = i + 1;
        del_obj[el.name + i] = del_obj[el.name + k];
      }
      if (!isArr(del_obj)) {
        delete del_obj[el.name + arr.length];
      }
    });
    let del_detail = addProdDetails[objname];
    if (isArr(del_detail)) {
      del_detail.splice(j, 1);
    } else {
      del_detail = del_obj;
    }
    dispatch(
      setObjFields({
        ...objectGroupDetails,
        [objname]: del_obj,
      })
    );
    dispatch(
      setAppDetails({
        ...addProdDetails,
        [objname]: del_detail,
      })
    );
    for (let i = j; i < arr.length; i++) {
      let k = i + 1;
      if (bidLabels["CountryCode" + k]) {
        bidLabels["CountryCode" + i] = bidLabels["CountryCode" + k];
        delete bidLabels["CountryCode" + k];
      }
    }
    dispatch(setBidderLabel(bidLabels));
  };

  // delete the section added inside an objectgroup
  const deleteMoreSubFields = (objname, childobjname, index, j) => {
    let arr = subObjectGroupCounter[objname + childobjname];
    arr[index].splice(j, 1);
    dispatch(
      setSubObjCounter({
        ...subObjectGroupCounter,
        [objname + childobjname]: arr,
      })
    );
    let del_obj = subObjectGroupDetails[objname + childobjname];
    let field_obj = field_data.filter((obj) => {
      return obj.name === objname;
    });
    let inner_field_obj = field_obj[0].fields.filter((obj) => {
      return obj.name === childobjname;
    });
    inner_field_obj[0].fields.forEach((el) => {
      for (let i = j; i < arr[index].length; i++) {
        let k = i + 1;
        del_obj[el.name + index + i] = del_obj[el.name + index + k];
      }
    });
    dispatch(
      setSubObjFields({
        ...subObjectGroupDetails,
        [objname + childobjname]: del_obj,
      })
    );
  };
  const handleObjGroupFieldChange = (event, newVal, name) => {
    let eventName = event?.target?.name ?? name;
    let eventValue = name !== undefined ? newVal : event?.target?.value;
    let obj_data = eventName?.split("#");
    let obj_Field = objectGroupDetails[obj_data[0]];
    if (obj_data[1].includes("bidder")) {
      let index = parseInt(obj_data[1].split("bidder").pop());
      obj_Field["params" + index] = {};
    }

    if (
      obj_data[1].includes("adId") &&
      eventValue &&
      eventValue.includes("ub-popup")
    ) {
      handleBidderDisableChange(obj_data[1].split("adId").pop(), true);
    }
    if (obj_data[1]?.includes("sizes")) {
      obj_Field[obj_data[1]] =
        obj_Field[obj_data[1]].length > 0 ? obj_Field[obj_data[1]] : [];
      let notPresent = obj_Field[obj_data[1]].every((el, i) => {
        if (JSON.stringify(el) === JSON.stringify(eventValue)) {
          obj_Field[obj_data[1]].splice(i, 1);
          return false;
        }
        return true;
      });
      if (notPresent) {
        obj_Field[obj_data[1]] = eventValue;
      }
    } else {
      obj_Field[obj_data[1]] = eventValue;
    }
    dispatch(
      setObjFields({
        ...objectGroupDetails,
        [obj_data[0]]: obj_Field,
      })
    );
  };

  //on change sub object group fields header bidding
  const handleSubObjGroupFieldChange = (event, newVal, name) => {
    let eventName = event?.target?.name ?? name;
    let eventValue = name !== undefined ? newVal : event?.target?.value;
    let obj_data = eventName.split("#");
    let obj_Field = subObjectGroupDetails[obj_data[0]];
    obj_Field[obj_data[1]] = eventValue;
    dispatch(
      setSubObjFields({
        ...subObjectGroupDetails,
        [obj_data[0]]: obj_Field,
      })
    );
    return;
  };
  // on change bidders params for header bidding
  const handleHbParamsChange = (event, bidder, index) => {
    dispatch(
      setBidderParams({
        ...hbParams,
        [event.target.name + index]: event.target.value,
      })
    );
    let objectgroup_fields = JSON.parse(JSON.stringify(objectGroupDetails));
    if (bidder === "dailyhunt" && event.target.name != "partner_name")
      objectgroup_fields["hb_full_common_bidders"]["params" + index][
        event.target.name
      ] = parseInt(event.target.value);
    else
      objectgroup_fields["hb_full_common_bidders"]["params" + index][
        event.target.name
      ] = event.target.value;
    dispatch(setObjFields(objectgroup_fields));
  };

  // on change inner bidders params for header bidding
  const handleSubHbParamsChange = (event, bidder, index, i) => {
    let newIndex = typeof index === "number" ? index.toString() + i : index;
    let name = "mappings_full_hb_configbidders";
    if (subObjectGroupDetails[name]["bidder" + newIndex]?.length <= 0) {
      handleSubObjGroupFieldChange(
        {},
        bidder,
        "mappings_full_hb_configbidders#bidder" + newIndex
      );
    } else if (subObjectGroupDetails[name]["bidder" + newIndex] !== bidder) {
      let subCounter = JSON.parse(JSON.stringify(subObjectGroupCounter));
      let counterIndex = subCounter[name][index].length;
      newIndex = index.toString() + counterIndex.toString();
      addMoreSubFields("mappings_full_hb_config", "bidders", index);
      handleSubObjGroupFieldChange(
        {},
        bidder,
        "mappings_full_hb_configbidders#bidder" + newIndex
      );
    }

    let subParams = { ...subHbParams };
    subParams[event.target.name + newIndex] = event.target.value;
    if (event.target?.defaults) {
      event.target.defaults.forEach((param) => {
        subParams[param.name + newIndex] = param.value;
      });
    }
    dispatch(setSubBidderParams(subParams));
    let subGroupDetails = JSON.parse(JSON.stringify(subObjectGroupDetails));
    if (bidder === "dailyhunt" && event.target.name != "partner_name") {
      subGroupDetails["mappings_full_hb_configbidders"]["params" + newIndex][
        event.target.name
      ] = parseInt(event.target.value);
    } else {
      subGroupDetails["mappings_full_hb_configbidders"]["params" + newIndex][
        event.target.name
      ] = event.target.value;
      if (event.target?.defaults) {
        event.target.defaults.forEach((param) => {
          subGroupDetails["mappings_full_hb_configbidders"][
            "params" + newIndex
          ][param.name] = param.value;
        });
      }
    }
    dispatch(setSubObjFields(subGroupDetails));
  };

  // on change bidders label option for header bidding
  const handleBidderLabelChange = (event) => {
    let name = event.target.name.split("#").pop();
    let value = event.target.value;
    dispatch(
      setBidderLabel({
        ...bidderLabel,
        [name]: value,
      })
    );
  };
  // on change inner bidders label option for header bidding
  const handleInnerBidderLabelChange = (event) => {
    let name = event.target.name.split("#").pop();
    let value = event.target.value;
    dispatch(
      setInnerBidderLabel({
        ...innerBidderLabel,
        [name]: value,
      })
    );
  };

  const handleUploadChange = (event, setValue, fieldName) => {
    if (!event) {
      console.error("Event is null or undefined.");
      return;
    }
    if (
      !event.target ||
      !event.target.files ||
      event.target.files.length === 0
    ) {
      console.error("No files selected.");
      return;
    }

    let file = event.target.files[0];
    let heading = props.heading.toLowerCase();
    let name = file.name;
    let clientname = props.clientname;
    let path = heading === "newsbot" ? "newsbot/" : "directcampaign/images/";

    const reader = new FileReader();
    reader.onload = async (event) => {
      let url = process.env.REACT_APP_API_URL + "client/uploadImage";
      try {
        const res = await axios({
          url: url,
          method: "POST",
          data: Buffer.from(event.target.result),
          headers: {
            "Content-Type": "application/octet-stream",
            "X-File-Name": name,
            "X-File-Path": path,
            "X-File-Cn": clientname,
            "X-File-heading": heading,
          },
        });
        if (res.data.err) {
          props?.showAlertMsg(res.data.msg);
          setValue(null, "", fieldName);
        } else {
          props?.showSuccessMsg(res.data.msg);
          setValue(null, name, fieldName);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        props?.showAlertMsg("Error uploading image. Please try again.");
        setValue(null, name, fieldName);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleSubChange = (event, saveType) => {
    if (
      event != null &&
      event != undefined &&
      props.catName !== undefined &&
      event.target.name === props.catName
    ) {
      props.setBotCatNum((prev) => event.target.value);
    }
    if (event && event.target && event.target.name === "cubeType") {
      setGcCubeType(event.target.value);
    }
    if (event && event.target && event.target.name === "directAdType") {
      setDirectAdType(event.target.value);
    }
    if (event != null)
      dispatch(
        setAppDetails({
          ...addProdDetails,
          [event.target.name]:
            saveType == "boolean"
              ? event.target.value == "true"
              : saveType == "number"
              ? isNaN(parseInt(event.target.value))
                ? ""
                : parseInt(event.target.value)
              : saveType === "switchbool"
              ? event.target.checked
              : saveType == "float"
              ? parseFloat(event.target.value)
              : event.target.value,
        })
      );
    if (event && event.target.name == "SIZE") {
      let sizes = event.target.value.split("x");
      dispatch(
        setAppDetails({
          ...addProdDetails,
          ["SIZE"]: event.target.value,
          ["PLAYER_WIDTH"]: parseInt(sizes[0]),
          ["PLAYER_HEIGHT"]: parseInt(sizes[1]),
        })
      );
    } else if (event && event.target.name == "SIZE_MB") {
      let sizes_mb = event.target.value.split("x");
      dispatch(
        setAppDetails({
          ...addProdDetails,
          ["SIZE_MB"]: event.target.value,
          ["PLAYER_WIDTH_MB"]: parseInt(sizes_mb[0]),
          ["PLAYER_HEIGHT_MB"]: parseInt(sizes_mb[1]),
        })
      );
    }
  };
  const handleBidderDisableChange = (index, checked) => {
    let bidder_disabled = { ...biddersDisabled };
    bidder_disabled["bidders" + index] = checked;
    dispatch(setBiddersDisabled(bidder_disabled));
  };
  const labelHelper = (labelObj, codes) => {
    for (let key in labelObj) {
      let code = codes.filter((code) => code.includes(labelObj[key]));
      if (code.length > 0) {
        let arr = [];
        arr.push(code[0]);
        labelObj[key] = arr;
      }
    }
    return labelObj;
  };
  const setLabels = (codes) => {
    let bidLabels = JSON.parse(JSON.stringify(bidderLabel));
    let innerBidLabels = JSON.parse(JSON.stringify(innerBidderLabel));
    bidLabels = labelHelper(bidLabels, codes);
    innerBidLabels = labelHelper(innerBidLabels, codes);
    dispatch(setBidderLabel(bidLabels));
    dispatch(setInnerBidderLabel(innerBidLabels));
  };
  const getCountryCodes = async () => {
    const url =
      process.env.REACT_APP_COUNTRY_API ?? "https://restcountries.com/v3.1/all";
    fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        data.forEach((el, i) => {
          data[i] = data[i]["name"]["common"] + " - " + data[i]["cca2"];
        });
        data.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        setCountryCodes(data);
        setLabels(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (props.fieldSet) {
      getCountryCodes();
    }
  }, [props.fieldSet]);

  useEffect(() => {
    if (props.fieldSet || !trigger) {
      setFields(
        <span>
          <SetFields
            data={field_data}
            userData={userData}
            heading={props.heading}
            editFlag={props.editFlag}
            handleUploadChange={handleUploadChange}
            prodDetails={addProdDetails}
            objDetails={objectGroupDetails}
            subObjDetails={subObjectGroupDetails}
            handleSubChange={handleSubChange}
            fieldChange={handleObjGroupFieldChange}
            subFieldChange={handleSubObjGroupFieldChange}
            paramChange={handleHbParamsChange}
            subParamChange={handleSubHbParamsChange}
            addField={addMoreFields}
            addSubField={addMoreSubFields}
            objCounter={objectGroupCounter}
            subObjCounter={subObjectGroupCounter}
            label={bidderLabel}
            innerLabel={innerBidderLabel}
            labelChange={handleBidderLabelChange}
            innerLabelChange={handleInnerBidderLabelChange}
            deleteField={deleteMoreFields}
            deleteSubField={deleteMoreSubFields}
            trigger={trigger}
            setTrigger={setTrigger}
            countryCodes={countryCodes}
            biddersDisabled={biddersDisabled}
            handleBidderDisableChange={handleBidderDisableChange}
            gcCubeType={gcCubeType}
            setGcCubeType={setGcCubeType}
            directAdType={directAdType}
            setDirectAdType={setDirectAdType}
            placeHolders={placeHolders}
          />
        </span>
      );
    }
  }, [
    props.editFlag,
    props.heading,
    userData,
    addProdDetails,
    objectGroupDetails,
    subObjectGroupDetails,
    objectGroupCounter,
    subObjectGroupCounter,
    trigger,
    hbParams,
    subHbParams,
    countryCodes,
    bidderLabel,
    innerBidderLabel,
    biddersDisabled,
  ]);
  return fields;
}

export default ObjGroup;
